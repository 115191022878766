<template>
  <div>
    <records></records>
    <!-- <b-tabs
      pills
      active-nav-item-class="bg-light-primary border-0 shadow-none cursor-pointer"
    >
      <b-tab :title="lang('t_records')" active>
        <records></records>
      </b-tab>
      <b-tab :title="lang('t_qualityForm')">
        <quality-form></quality-form>
      </b-tab>
      <b-tab :title="lang('t_keywordCategories')">
        <key-words></key-words>
      </b-tab>
    </b-tabs> -->
  </div>
</template>

<script>
import { BFormRadioGroup, BFormGroup, BRow, BCol, BCard, BCardText, BLink, BButton, BTabs, BTab } from 'bootstrap-vue'
// import SmsSender from './SmsSender.vue'

import Records from './Records.vue'
// import QualityForm from './list/List.vue'
// import KeyWords from './KeyWordCategories/List.vue'


export default {
  data() {
    return {
      lang: _l(window.localStorage.getItem('language') || "tr"),
    }
  },
  components: {
    Records,
    // QualityForm,
    // KeyWords,
    BFormRadioGroup,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BButton,
    BTabs,
    BTab
  },

}
</script>

<style>
</style>
